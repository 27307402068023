import axios from 'axios';

// baseURL 后端服务地址
const myAxios = axios.create({
    baseURL: 'https://manage.siyuan.press/yanxue/'  // 生产环境
    // baseURL: 'http://192.168.50.109:5003/yanxue/'  // 本地测试环境
});

myAxios.defaults.withCredentials = true; // 允许携带 cookie
// 添加请求拦截器
myAxios.interceptors.request.use(function (config) {
    console.log('我要发送请求了,', config);
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
myAxios.interceptors.response.use(function (response) {
    console.log('我收到你的响应了,', response);
    return response.data;
}, function (error) {
    return Promise.reject(error);
});


export default myAxios;
