<template>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in images" :key="image">
      <img :src="`https://manage.siyuan.press/${image}`" style="width:100%;height: 195px;"/>
    </van-swipe-item>
  </van-swipe>

  <div class="preface_title">
    <div class="preface_title_text">
      挑战自己，从现在开始
    </div>
  </div>
  <div class="preface_content">
    <!--    <div v-for="(paragraph, index) in paragraphs" :key="index" class="paragraph">-->
    <!--      {{ paragraph }}-->
    <!--    </div>-->
    <div class="paragraph"  v-html="paragraphs">
    </div>
  </div>
  <template v-if="paragraphs.length === 0">
    <van-empty description="活动详情为空"/>
  </template>
  <div class="preface_content_btn_box">
    <van-button :disabled="isButtonDisabled" class="btn" type="primary" @click="onSubmit">{{ btnMsg }}</van-button>
  </div>

</template>

<script setup>
import myAxios from "@/plugins/myAxios";
import {computed, onMounted, ref} from "vue";
import {useStore} from 'vuex'; // 导入 useStore
import {useRoute} from "vue-router";
// import {useRouter} from "vue-router";
import {showToast} from "vant";
import { showLoadingToast } from 'vant';

const store = useStore();
const route = useRoute();
// const router = useRouter();
//获取 activityId 和 userId
const activityParamId = route.query.activity_id
console.log("activityParamId", activityParamId)
const userParamId = route.query.user_id
localStorage.setItem("activityParamId", activityParamId)
localStorage.setItem("userParamId", userParamId)
const btnMsg = ref("我要报名参加");
const isButtonDisabled = ref(false);
// const activityId = 3
// const userId = 53
const paragraphs = ref('');
const images = ref([])
const activity = computed(() => store.getters.getActivity);
const activityId = activity.value.id
console.log("activityId", activityId)

const toast = showLoadingToast({
  message: '加载中...',
  forbidClick: true,
});

onMounted(async () => {
      // 获取页面内容
      if (activityParamId != null) {
        const res = await myAxios.get(`/activityDetail/${activityParamId}`)
        if (!res.success) {
          isButtonDisabled.value = true;
          showToast(res.msg)
        } else {
          toast.close()
          if (res.data.status === 0) {
            showToast("活动已关闭")
            btnMsg.value = "活动已结束"
            isButtonDisabled.value = true;
          } else {
            paragraphs.value = res.data.content
            store.commit('setActivity', res.data);
            localStorage.setItem("activity", JSON.stringify(res.data))
            console.log("after activity", activity.value)
            document.title = activity.value.name;
          }
        }
      } else {
        showToast("没有活动")
        isButtonDisabled.value = true;
      }
      const imgs = await myAxios.get("/activityBanner")
      images.value = imgs.data
      console.log(images.value)
    }
)
const redirectUri = encodeURI(`https://www.siyuan.press/form/${activityParamId}/${userParamId}`)
const onSubmit = () => {
//   //判断时间是否过期
//   const startTimeStr = activity.value.begin_time; // 开始时间
//   const endTimeStr = activity.value.end_time;   // 截止时间
//   const startTime = new Date(startTimeStr); // 将字符串转换为 Date 对象
//   const endTime = new Date(endTimeStr);     // 将字符串转换为 Date 对象
//   // 获取当前时间
//   const currentTime = Date.now();
// // 判断活动状态
//   const hasStarted = currentTime >= startTime;
//   const isExpired = currentTime > endTime;
//   console.log("")
//   if (isExpired) {
//     showToast("活动已过期");
//   } else if (!hasStarted) {
//     showToast("活动尚未开始");
//   } else {
    console.log("redirectUri", redirectUri)
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?` +
        `appid=wxfa343e820df74c3c` +
        `&redirect_uri=${redirectUri}` +
        `&response_type=code` +
        `&scope=snsapi_base` +
        `&state=123#wechat_redirect`;
    // router.push({
    //   path: `/form/${activityParamId}/${userParamId}`,
    //   query:{
    //     code: '123456'
    //   }
    // })
  // }
};

</script>

<style>
.paragraph {
  font-size: 1.828rem;
  line-height: 2.716rem;
  margin-bottom: 0.96rem;
  margin-top: 0.96rem;
  text-align: justify;
  white-space: pre-wrap
}

.btn {
  width: 100%;
  height: 4.224rem;
  font-family: "PFMedium";
  font-size: 1.536rem;
  font-weight: bold;
  line-height: 4.224rem;
  text-align: center;
  color: #ffffff;
  margin-top: 1.344rem;
  margin-bottom: 2.304rem;
  border-radius: 2.112rem;
  background-color: #40a0f7;
}

.preface_title_text {
  background-color: #0064cf;
  border-radius: 0.384rem;
  width: 21.648rem;
  height: 3.264rem;
  margin-left: 1.248rem;
  text-align: center;
  line-height: 3.264rem;
  font-size: 1.344rem;
  color: #fff;

}

/* 首页大标题 */
.preface_title {
  width: 24rem;
  height: 3.264rem;
  position: relative;
  margin: -1.344rem auto 0;
  z-index: 1;
}

/* 首页内容 */
.preface_content {
  width: 29.472rem;
  margin: 0 auto;
}

.preface_content_btn_box {
  width: 33.12rem;
  margin: 0 auto;
}

pre {
  font-family: micsoft yahei;
  white-space: pre-wrap; /* 保留空格和换行 */
  word-wrap: break-word; /* 处理长单词 */
  /* 去除默认外边距 */
  font-size: 1.248rem;
  line-height: 2.016rem;
  margin: 0 0 0.96rem;
  text-align: justify;


}
</style>