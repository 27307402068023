<template>
  <div id="content">
    <div class="preface_title">
      <div class="preface_title_text">我要报名</div>
    </div>
    <div class="vueBox">
      <van-form :model="formState" style="margin-bottom: 16px" @submit="onSubmit">
        <van-cell-group inset style="margin-right: 0; margin-left: 0">
          <van-field class="labelInfo" label="基本信息"></van-field>
          <van-field
            v-model="formState.name"
            class="field"
            label="*"
            placeholder="请输入学生姓名"
          />
          <van-field
            v-model="formState.school"
            class="field"
            label="*"
            placeholder="现就读学校名称"
          />
          <van-field
            v-model="formState.grade"
            class="field"
            is-link
            label="*"
            name="picker"
            placeholder="请选择现就读年级"
            readonly
            @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
              :columns="gradeColumns"
              @cancel="showPicker = false"
              @confirm="onConfirmGrade"
            />
          </van-popup>
          <van-field
            v-model="formState.address"
            class="field"
            label="*"
            placeholder="通讯详细地址"
          />
          <van-field class="labelInfo" label="监护人信息"></van-field>
          <van-field
            v-model="formState.guardian"
            class="field"
            label="*"
            placeholder="监护人姓名"
          />
          <van-field
            v-model="formState.contact"
            class="field"
            label="*"
            placeholder="监护人联系方式1"
          />
          <van-field v-model="formState.contact2" placeholder="监护人联系方式2" />
          <van-field class="labelInfo" label="联系教师信息"></van-field>
          <van-field :placeholder="manager" class="field" disabled label="*" />
          <van-field :placeholder="managerPhone" class="field" disabled label="*" />
        </van-cell-group>
        <!--        <div style="margin: 16px;">-->
        <!--          <van-button round block type="primary" native-type="submit">-->
        <!--            提交-->
        <!--          </van-button>-->
        <!--        </div>-->
      </van-form>
    </div>
    <div class="online-appt__btn-group">
      <van-button
        class="nextBtn"
        native-type="submit"
        type="primary"
        @click="onSubmit"
        :disabled="disabledFlag"
      >
        下一步
      </van-button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { showLoadingToast, showToast } from "vant";
import myAxios from "@/plugins/myAxios";
import { useRoute, useRouter } from "vue-router";
// import {useStore} from "vuex";

const route = useRoute();
// const store = useStore();
const router = useRouter();
let formState = ref({
  name: "",
  school: "",
  grade: "",
  address: "",
  guardian: "",
  contact: "",
  contact2: "",
});
const manager = ref("");
const managerPhone = ref("");
const showPicker = ref(false);
const gradeColumns = [
  { text: "三年级", value: "3rd" },
  { text: "四年级", value: "4th" },
  { text: "五年级", value: "5th" },
  { text: "六年级", value: "6th" },
  { text: "七年级", value: "7th" },
  { text: "八年级", value: "8th" },
];
const activityId = route.params.activityId;
const userId = route.params.userId;
const disabledFlag = ref(false);
//获取的code参数
const code = route.query.code;
console.log("code", code);
localStorage.setItem("code", code);
// const state = route.query.state
console.log("url", route.path);
const onConfirmGrade = ({ selectedOptions }) => {
  formState.value.grade = selectedOptions[0]?.text || "";
  showPicker.value = false;
};
const activity = JSON.parse(localStorage.getItem("activity"));

onMounted(async () => {
  if (activity == null || code == null || code !== localStorage.getItem("code")) {
    // 跳转首页
    await router.push({
      path: "/",
      query: {
        activity_id: activityId,
        user_id: userId,
      },
    });
  } else {
    document.title = activity.name;
    manager.value = activity.manager;
    managerPhone.value = activity.phone;
    console.log("activity.id", activity.id);
    const savedState = localStorage.getItem("form");
    if (savedState) {
      formState.value = JSON.parse(savedState);
    }
    console.log(formState.value);
  }
});

const onSubmit = async () => {
  if (!validateForm()) {
    showToast(errors.value);
  } else {
    disabledFlag.value = true; // 禁用按钮
    // 开启加载
    const toast = showLoadingToast({
      message: "加载中...",
      forbidClick: true,
    });
    localStorage.setItem("form", JSON.stringify(formState.value));
    console.log("activityId", activityId);
    console.log("userId", userId);
    // 可发送请求保存表单数据
    const res = await myAxios.post("/activitySign", {
      activity_id: activityId,
      user_id: userId,
      code: code,
      ...formState.value,
    });

    toast.close(); // 关闭加载
    disabledFlag.value = false; // 启用按钮

    if (!res.success) {
      showToast(res.msg);
    } else {
      const prepayId = res.data.prepay_id;
      localStorage.setItem("prepayId", prepayId);
      await router.push({
        path: `/pay/${activityId}/${userId}`,
        query: {
          code: code,
        },
      });
    }
  }
};
const errors = ref({}); // 用于存储错误信息
// 校验表单数据的函数
const validateForm = () => {
  errors.value = ""; // 重置错误信息
  // 检查每个字段
  if (!formState.value.name) {
    errors.value = "学生姓名必填";
    return false;
  }
  if (!formState.value.school) {
    errors.value = "现就读学校名称必填";
    return false;
  }
  if (!formState.value.grade) {
    errors.value = "现就读年级必填";
    return false;
  }
  if (!formState.value.address) {
    errors.value = "通讯详细地址必填";
    return false;
  }
  if (!formState.value.guardian) {
    errors.value = "监护人姓名必填";
    return false;
  }
  if (formState.value.contact) {
    const regex = /^1[3-9]\d{9}$/;
    if (!regex.test(formState.value.contact)) {
      errors.value = "请输入11位有效电话号码";
      return false;
    }
  } else {
    errors.value = "监护人联系方式1必填";
    return false;
  }
  if (formState.value.contact2) {
    const regex = /^1[3-9]\d{9}$/;
    if (!regex.test(formState.value.contact2)) {
      errors.value = "请输入11位有效电话号码";
      return false;
    }
  }
  return true;
};
</script>

<style>
#content {
  height: 100%;
  width: 100%;
  padding-top: 50px;
  background-color: #3380ff;
}

.labelInfo {
  background-color: #eeecec;
}

.labelInfo input {
  visibility: hidden;
}

.vueBox {
  padding: 3.84rem 0 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #ffffff;
  border-radius: 0.384rem;
  margin-top: -1.92rem;
  margin-bottom: 16px;
}

.nextBtn {
  width: 100%;
  height: 100%;
  font-family: "PFMedium";
  font-size: 1.344rem;
  line-height: 2.976rem;
  text-align: center;
  color: #3380ff;
  border-radius: 1.488rem;
  background-color: #ffffff;
}

.online-appt__btn-group {
  width: 10.272rem;
  height: 2.976rem;
  margin: 2.304rem auto 0;
  padding-bottom: 2.304rem;
}

.field .van-field__label {
  width: 0;
  color: red;
  font-family: "PFMedium";
  font-size: 1.344rem;
}

.field {
  height: 3.84rem;
}

.van-field__control::placeholder {
  font-family: "PFMedium";
  font-size: 1.344rem;
  color: #747474;
}
</style>
